<template>
  <div>
    <div :style="`padding-top:${this.screenSize >= 992 ? 90 : 70}px`">
      <Parallax
        id="parallax-header"
        class="invisible"
        v-if="imgUpper && !loading"
        :text="titleImg"
        :img="imgUpper"
        :newLine="true"
        :imgHeight="`${this.screenSize >= 992 ? 510 : 250}px`"
        :fontSize="parallaxSize.size"
      />
    </div>
    <div style="min-height: 100vh;">
      <PageInfomation
        id="parallax-info"
        v-if="!loading"
        :textUpper="textUpper"
        :textLower="textLower"
        :imgSwiper="imgSwiper"
      />
    </div>
    <Parallax
      id="parallax-footer"
      v-if="imgLower && !loading"
      :img="imgLower"
      :newLine="true"
      :imgHeight="`${parallaxSize.height}px`"
      :fontSize="parallaxSize.size"
    />
  </div>
</template>

<script>
import Parallax from "@/components/Parallax";
import $ from "jquery";
export default {
  components: {
    PageInfomation: () => import(`@/components/PageInfomation`),
    Parallax,
  },
  data() {
    return {
      tag: null,
      loading: true,
      imgSwiper: [],
      textUpper: null,
      textLower: null,
      imgUpper: null,
      titleImg: null,
      imgLower: null,
      isGroup: false,
      screenSize: null,
    };
  },
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
    parallaxSize() {
      return {
        height: this.screenSize >= 992 ? 580 : 250,
        size: this.screenSize >= 992 ? 50 : 30,
      };
    },
  },
  mounted() {
    console.log("mounted");
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.tag = this.$router.currentRoute.params.tag;
    this.getPageInfomation();
  },
  watch: {
    $route() {
      console.log(this.$route);
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("beforeRouteEnter");
    next((vm) => {
      vm.tag = to.params.tag;
      vm.getPageInfomation();
    });
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    this.tag = to.params.tag;
    this.getPageInfomation();
    next();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    async getPageInfomation() {
      console.log(this.tag);
      this.loading = true;
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["${this.tag}"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        const data = res.data.results[0].data;
        data.lower_text.forEach((arr) => {
          let text = arr.info[0].text;
          text = text.replaceAll("/*start*/", `<span class="text-red">`);
          text = text.replaceAll("/*end*/", "</span>");
          arr.info[0].text = text;
        });
        data.upper_text.forEach((arr) => {
          let text = arr.info[0].text;
          text = text.replaceAll("/*start*/", `<span class="text-red">`);
          text = text.replaceAll("/*end*/", "</span>");
          arr.info[0].text = text;
        });
        this.textLower = data.lower_text;
        this.textUpper = data.upper_text;
        this.imgUpper = data.img_banner.url;
        this.imgLower = data.img_footer.url;
        this.isGroup = data.is_group;
        this.imgSwiper = data.swiper_img;
        this.titleImg = data.title_img[0].text;
        this.loading = false;
        const interval = setInterval(() => {
          if ($(`#parallax-header`).length > 0) {
            $(`#parallax-header`)
              .addClass("animate__animated animate__fadeInUp")
              .removeClass("invisible");
            clearInterval(interval);
          }
        }, 500);
        $(document).scroll(function () {
          const height = $(window).scrollTop() + $(window).height();
          if ($(`#parallax-footer`).length > 0) {
            if (height > $(`#parallax-footer`).offset().top) {
              $(`#parallax-footer`).addClass(
                "animate__animated animate__fadeIn"
              );
            }
          }
        });
      }
    },
    onResize() {
      this.screenSize =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    },
  },
};
</script>

<style>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>